import {useSearchParams} from "react-router-dom";
import {useCourses} from "../../../hooks/useCourses";
import Box from "@mui/joy/Box";
import {Alert, CircularProgress} from "@mui/joy";
import ErrorAlert from "../../../components/ErrorAlert";
import * as React from "react";
import Paginator from "../../../components/Paginator";
import CourseItem from "./CourseItem";

export default function CourseList({institutionIdDefault}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const name = searchParams.get('name') || '';
    const institutionId = [institutionIdDefault] || searchParams.getAll("institutionId");
    const jobTitleId = searchParams.getAll("jobTitleId");
    const type = searchParams.get("type") || '';
    const page = searchParams.get('page') || 1;
    const size = searchParams.get('size') || 10;
    const sort = searchParams.get('sort') || 'institutionId.name,jobTitle.name,name';
    const [data, loading, error] = useCourses(
        {
            institutionId,
            jobTitleId,
            type,
            published: true,
            name,
            page: page - 1,
            size,
            sort,
        }
    );

    if (loading) {
        return (
            <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </Box>
        );
    }

    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }

    if (!data) {
        return null;
    }

    const courses = data.content || [];
    const totalPages = data.totalPages || 0;

    if (courses.length === 0) {
        return (
            <Alert sx={{maxWidth: 800, width: 1, mx: 'auto'}}>Nenhum curso encontrado.</Alert>
        )
    }

    return (
        <>
            {courses.map(course =>
                <CourseItem key={course.id} course={course}/>
            )}
            <Paginator sx={{p: '16px'}}
                       page={page}
                       totalPages={totalPages}
                       onPageChange={page => setSearchParams({page})}/>
        </>

    )
}