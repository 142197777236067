import Checkbox from '@mui/joy/Checkbox';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import * as React from "react";
import {Controller} from "react-hook-form";

export default function ModuleList({control}) {
    const modules = [
        {label:'PLANEJAMENTO DE ESTUDOS', name: 'PLANEJAMENTO', selected: true},
        {label:'MENTORIA', name: 'MENTORIA', selected: true},
        {label:'REDATOP', name:'REDATOP', selected: false},
        {label:'PREPARACAO PARA A DISCURSIVA', name:'DISCURSIVA', selected: false},
        {label:'APROVAÇÃO EM', name: 'APROVACAO', selected: true},
        {label:'EVENTOS ONLINE', name: 'EVENTOS', selected: false},
        {label:'SIMULADOS', name: 'SIMULADO', selected: true},
        {label:'DETONANDO A BANCA', name: 'DETONANDO', selected: true},
    ].map(module =>
        (
            <ListItem sx={{pl: '0px'}} key={module.name}>
                <Controller
                    name={'initialModules.' + module.name}
                    control={control}
                    defaultValue={module.selected}
                    render={({field: {onChange, value}}) =>
                        <Checkbox size="sm"
                                  label={module.label}
                                  onChange={event => onChange(event.target.checked)}
                                  checked={value}/>
                    }/>
            </ListItem>
        )
    );


    return (
        <>
            <FormControl size='sm' sx={{mt: 1}}>
                <FormLabel>Módulos Iniciais</FormLabel>
            </FormControl>
            <div role="group" aria-labelledby="sandwich-group">
                <List size="sm" orientation="vertical" sx={{pl: '0px'}}>
                    {modules}
                </List>
            </div>
        </>
    );
}
