import {useState} from "react";
import axios from "axios";
import {DialogActions, DialogContent, Modal, ModalDialog} from "@mui/joy";
import DialogTitle from "@mui/joy/DialogTitle";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Divider from "@mui/joy/Divider";
import Button from "@mui/joy/Button";
import * as React from "react";
import {mutate} from "swr";
import ErrorAlert from "../../../../../components/ErrorAlert";

export default function SectionRemoveModal({moduleId, section, open, onClose}) {
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    const handleClick = () => {
        setSubmitting(true);
        setSubmitError(null);
        axios.delete(`module/${moduleId}/section/${section.id}`)
             .then(response => {
                 mutate((key) => typeof key === 'string' && key.startsWith(`/module/${moduleId}`))
                     .then(() => onClose());
             })
             .catch(error => {
                 setSubmitError(error);
             })
             .finally(() => setSubmitting(false));
    }

    const handleClose = () => {
        setSubmitError(null);
        onClose();
    }

    const message = `Deseja remover o seção ${section.name}?`;

    return (
        <Modal open={open} onClose={handleClose}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <WarningRoundedIcon/>
                    Remover Seção
                </DialogTitle>
                <Divider/>
                <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
                <DialogContent>
                    {message}
                </DialogContent>
                <DialogActions>
                    <Button loading={submitting} variant="solid" color="danger" onClick={handleClick}>
                        Remover
                    </Button>
                    <Button variant="plain" color="neutral" onClick={handleClose}>
                        Cancelar
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}
