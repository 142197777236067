import {Alert, Table} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import * as React from "react";
import ModuleRow from "./ModuleRow";
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import {useState} from "react";
import axios from "axios";
import {mutate} from "swr";
import ErrorAlert from "../../../../../components/ErrorAlert";

export default function ModuleTable({courseId, defaultModules = []}) {
    const [modules, setModule] = useState(defaultModules);
    const [submiting, setSubmiting] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    if (modules.length === 0) {
        return (
            <Alert sx={{maxWidth: 'lg', width: 1, mx: 'auto'}}>Nenhum módulo adicionado.</Alert>
        )
    }

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let backup = JSON.parse(JSON.stringify(modules));
        let tempData = Array.from(modules);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        for (let i = 0; i < tempData.length; i++) {
            tempData[i].position = i + 1;
        }
        setModule(tempData);
        axios.patch(`course/${courseId}/module/position`, tempData.map(m => m.id))
            .then(() => {
                return mutate((key) => typeof key === 'string' && key.startsWith(`/course/${courseId}`));
            })
            .catch(error => {
                setModule(backup);
                setSubmitError(error);
            })
            .finally(() => setSubmiting(false));
    };

    return (
        <>
            <ErrorAlert error={submitError} onClose={() => setSubmitError(undefined)}/>
            <Sheet
                className="OrderTableContainer"
                variant="outlined"
                sx={{
                    display: 'initial',
                    mx: 'auto',
                    borderRadius: 'sm',
                    flexShrink: 1,
                    overflow: 'auto',
                    minHeight: 0,
                }}
            >
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Table
                        aria-labelledby="tableTitle"
                        stickyHeader
                        hoverRow
                        sx={{
                            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                            '--Table-headerUnderlineThickness': '1px',
                            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                            '--TableCell-paddingY': '4px',
                            '--TableCell-paddingX': '8px',
                        }}
                    >
                        <thead>
                        <tr>
                            <th style={{width: 80, padding: '12px 12px 12px 16px'}}></th>
                            <th style={{width: 80, padding: '12px 12px 12px 16px'}}>Posição</th>
                            <th style={{width: 240, padding: '12px 6px'}}>Nome</th>
                            <th style={{width: 80, padding: '12px 6px'}}></th>
                        </tr>
                        </thead>
                        <Droppable droppableId="droppable-1">
                            {(provider) => (
                                <tbody ref={provider.innerRef} {...provider.droppableProps}>
                                {modules.map((module, index) => (
                                    <Draggable key={module.id} draggableId={module.id.toString()} index={index}>
                                        {(provider) => (
                                            <ModuleRow key={module.id} courseId={courseId} module={module} provider={provider}/>
                                        )}
                                    </Draggable>
                                ))}
                                {provider.placeholder}
                                </tbody>
                            )}
                        </Droppable>
                    </Table>
                </DragDropContext>
            </Sheet>
        </>
    )
}
