import {AspectRatio} from "@mui/joy";
import Box from "@mui/joy/Box";
import * as React from "react";
import {Link as RouterLink} from "react-router-dom";
import {useContext} from "react";
import {UserContext} from "../../../contexts/UserContext";
import {containsAuthority} from "../../../utils";

export default function PremiumBanner({authority}) {
    const user = useContext(UserContext);

    if (containsAuthority(authority, user)) {
        return undefined;
    }

    return (
        <Box sx={{width: 1, p: 1}} component={RouterLink}
             to='https://cursosdoportal.com.br/cursosportal/assinaturapremium2-2/' target='_blank'>
            <AspectRatio objectFit="contain" variant='plain' minHeight={120} maxHeight={200}>
                <img
                    src="https://cursosdoportal.com.br/wp-content/uploads/2024/08/teste1.png"
                    alt="A beautiful landscape."
                />
            </AspectRatio>
        </Box>
    )
}