import {Alert, IconButton} from "@mui/joy";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function ErrorAlert({error, onClose}) {
    if (!error) {
        return null;
    }
    console.error(error);
    let message;
    if (error.code === 'ERR_NETWORK') {
        message = 'Serviço indisponível.';
    } else if (error.response) {
        if(error.response.data.description) {
            message = error.response.data.description;
        } else if (error.response.status === 401) {
            message = 'Acesso não autorizado.';
        } else if (error.response.status === 403) {
            message = 'Você não tem permissão suficiente para acessar este recursos.';
        } else {
            message = "Erro inesperado, entre em contado com o suporte se o problema persistir.";
        }
    }
    return (
        <Alert color="danger" endDecorator={
            <IconButton variant="plain" size="sm" color="danger" onClick={onClose}>
                <CloseRoundedIcon/>
            </IconButton>
        }>
            {message}
        </Alert>
    );
}
