import {FormHelperText, Grid} from "@mui/joy";
import {Controller, useForm} from "react-hook-form";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import * as React from "react";
import {useState} from "react";
import ErrorAlert from "../../../../../components/ErrorAlert";
import Button from "@mui/joy/Button";
import axios from "axios";
import Divider from "@mui/joy/Divider";
import {mutate} from "swr";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/joy/Typography";

export default function ModuleEditForm({courseId, module}) {
    const [submiting, setSubmiting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const {handleSubmit, control, formState: {errors}} = useForm({mode: 'all'});
    const navigate = useNavigate();

    if (!module || !courseId) {
        return null;
    }

    const handleBack = () => {
        navigate('/registration/course/edit/' + courseId);
    }
    const onSubmit = (data) => {
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        setSubmiting(true);
        setSubmitError(undefined);
        axios.put(`course/${courseId}/module/${module.id}`, data)
             .then(response => {
                 return mutate((key) => typeof key === 'string' && key.startsWith(`/course/${courseId}`), undefined,
                     {revalidate: true})
                     .then(() => handleBack());
             })
             .catch(error => {
                 setSubmitError(error);
             })
             .finally(() => setSubmiting(false));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{mt: 4, maxWidth: 'lg', mx: 'auto'}}>
                <Box sx={{mb: 1}}>
                    <Typography level="title-md">Dados do Módulo</Typography>
                </Box>
                <Divider/>
                <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
                <Grid container spacing={1}>
                    <Grid sm={3} xs={12}>
                        <Controller
                            name="position"
                            defaultValue={module.position}
                            control={control}
                            rules={{required: 'Campo obrigatório'}}
                            helperText={errors.position?.message}
                            render={({field}) => <FormControl size="sm" sx={{flexGrow: 1}} error={!!errors.position}>
                                <FormLabel>Posição</FormLabel>
                                <Input type="number" {...field}/>
                                <FormHelperText>{errors.position?.message}</FormHelperText>
                            </FormControl>}
                        />
                    </Grid>
                    <Grid sm={9} xs={12}>
                        <Controller
                            name="name"
                            defaultValue={module.name}
                            control={control}
                            rules={{required: 'Campo obrigatório'}}
                            helperText={errors.name?.message}
                            render={({field}) => <FormControl size="sm" sx={{flexGrow: 1}} error={!!errors.name}>
                                <FormLabel>Nome</FormLabel>
                                <Input type="text" {...field}/>
                                <FormHelperText>{errors.name?.message}</FormHelperText>
                            </FormControl>}
                        />
                    </Grid>
                </Grid>
                <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                    <CardActions sx={{justifyContent: 'space-between', pt: 2}}>
                        <Button variant="plain" color="neutral" onClick={handleBack}>
                            Voltar
                        </Button>
                        <Button loading={submiting} type="submit" size="sm" variant="solid">
                            Salvar
                        </Button>
                    </CardActions>
                </CardOverflow>
            </Card>
        </form>
    )
}
