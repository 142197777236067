import {AspectRatio} from "@mui/joy";
import ImageIcon from "@mui/icons-material/Image";
import * as React from "react";

export default function CourseBackgroundPreview({course}) {
    if (course.background) {
        return (
            <AspectRatio minHeight="120px" maxHeight="200px" sx={{width: 300}}>
                <img
                    src={`${process.env.REACT_APP_SERVER_URL}course/${course.id}/background`}
                    alt="Background"
                />
            </AspectRatio>
        )
    } else {
        return <ImageIcon sx={{fontSize: '12rem', opacity: 0.2}}/>
    }
}