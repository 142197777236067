export const openSidebar = () => {
    if (typeof document !== 'undefined') {
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
        document.documentElement.style.removeProperty('--SideNavigation-margin');
    }
};

export const closeSidebar = () => {
    if (typeof document !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.documentElement.style.setProperty('--SideNavigation-margin', '0');
    }
};

export const toggleSidebar = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--SideNavigation-slideIn');
        if (slideIn) {
            closeSidebar();
        } else {
            openSidebar();
        }
    }
};

export const containsAuthority = (authority, user) => {
    if (!user) {
        throw new Error("User cannot be undefined")
    }
    const authorities = user.authorities.map(a => a.authority) || [];
    if (!authorities) {
        return false;
    }
    return authorities.includes(authority)
}

export const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result.split(',')[1]);
        };
    });
};