import useSWR from "swr";
import {fetcher} from "../core/axios-config";

export function useInstitution(id) {
    const {data, error, isLoading} = useSWR(id ? `/institution/${id}` : undefined, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
    });
    return [data, isLoading, error];
}

