import {Breadcrumbs} from "@mui/joy";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import {Link as RouterLink} from "react-router-dom";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import * as React from "react";

export default function Breadcrumb({paths = [], current}) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Breadcrumbs
                size="sm"
                aria-label="breadcrumbs"
                separator={<ChevronRightRoundedIcon fontSize="sm"/>}
                sx={{pl: 0}}
            >
                <Link
                    underline="none"
                    color="neutral"
                    aria-label="Home"
                    component={RouterLink} to="/"
                >
                    <HomeRoundedIcon/>
                </Link>
                {paths.map(path => (
                    <Link
                        key={path.link}
                        underline="hover"
                        color="neutral"
                        component={RouterLink} to={path.link}
                        fontSize={12}
                        fontWeight={500}
                    >
                        <Typography fontWeight={500} fontSize={12}>
                            {path.name}
                        </Typography>
                    </Link>
                ))}
                <Typography color="primary" fontWeight={500} fontSize={12}>
                    {current.name}
                </Typography>
            </Breadcrumbs>
        </Box>
    )
}