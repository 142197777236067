import {Modal, ModalDialog} from "@mui/joy";
import DialogTitle from "@mui/joy/DialogTitle";
import * as React from "react";
import LinkForm from "./LinkForm";

export default function LinkEditModal({lecture, link, open, onClose}) {
    if (!lecture) {
        throw new Error("lecture cannot be null");
    }
    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog sx={{minWidth: {md: 500}}}>
                <DialogTitle>{link ? 'Alterar' : 'Adicionar'} Link</DialogTitle>
                <LinkForm lecture={lecture} link={link} onClose={onClose}/>
            </ModalDialog>
        </Modal>
    );
}
