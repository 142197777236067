import {Autocomplete, CircularProgress} from "@mui/joy";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import {Controller} from "react-hook-form";
import * as React from "react";
import {useDomain} from "../../hooks/useDomain";
import ErrorAlert from "../ErrorAlert";

export default function DomainAutocomplete({
                                               control,
                                               name,
                                               defaultValue = [],
                                               domain,
                                               title,
                                               sort,
                                           }) {
    const [data, loading, error] = useDomain({domain, sort});

    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }
    if (loading) {
        return (
            <FormControl size='sm' sx={{flexGrow: 1}}>
                <FormLabel>{title}</FormLabel>
                <Autocomplete options={[]}
                              limitTags={2}
                              noOptionsText="Sem opções"
                              loading={loading}
                              loadingText="Carregando..."
                              endDecorator={
                                  loading ? (
                                      <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
                                  ) : null
                              }
                />
            </FormControl>
        )
    }

    const items = data?.content || [];
    const value = items.filter(i => defaultValue.includes(i.id.toString()));

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            render={({field: {onChange, value}}) =>
                <FormControl size='sm' sx={{flexGrow: 1}}>
                    <FormLabel>{title}</FormLabel>
                    <Autocomplete multiple
                                  getOptionLabel={option => option.name}
                                  getOptionKey={option => option.id}
                                  options={items}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  onChange={(event, newValue) => onChange(newValue)}
                                  value={value}
                                  limitTags={2}
                                  noOptionsText="Sem opções"
                                  loading={loading}
                                  loadingText="Carregando..."
                                  endDecorator={
                                      loading ? (
                                          <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
                                      ) : null
                                  }
                    />
                </FormControl>}/>

    )
}
