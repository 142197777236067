import Box from "@mui/joy/Box";
import {useParams} from "react-router-dom";
import * as React from "react";
import {useCourse} from "../../../../hooks/useCourse";
import CourseBasicDataCard from "./CourseBasicDataCard";
import ErrorAlert from "../../../../components/ErrorAlert";
import {CircularProgress} from "@mui/joy";
import Breadcrumb from "../../../../components/Breadcrumb";
import PageTitle from "../../../../components/PageTitle";
import CourseModulesCard from "./CourseModulesCard";
import CourseBackgroundCard from "./CourseBackgroundCard";

export default function CourseEditPage() {
    let {id} = useParams();
    id = isNaN(Number(id)) ? undefined : id;
    const [course, loading, error] = useCourse(id);

    if (!course) {
        return undefined;
    }

    return (
        <>
            <Breadcrumb paths={[{name: 'Cadastro de Cursos', link: '/registration/course'}]}
                        current={{name: 'Editar Curso'}}/>
            <PageTitle>Editar Curso</PageTitle>
            {error ? <ErrorAlert error={error}/> : undefined}
            {loading ? (
                <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress/></Box>
            ) : (
                <>
                    <CourseBasicDataCard course={course}/>
                    <CourseModulesCard course={course}/>
                    <CourseBackgroundCard course={course}/>
                </>
            )}

        </>
    );
}
