import {Autocomplete, CircularProgress} from "@mui/joy";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import {Controller} from "react-hook-form";
import * as React from "react";

const getValue = (value, disciplineId) => {
    if (!value) {
        return undefined;
    }
    if (value instanceof Array) {
        return value.filter(v => disciplineId.includes(v.discipline?.id));
    } else {
        return disciplineId.includes(value.discipline?.id) ? value : null;
    }
}

export default function SubjectAutocompleteContent({
                                                disciplineId = [],
                                                control,
                                                defaultValue = [],
                                                subjects,
                                                loading
                                            }) {

    return (
        <Controller
            name="subjectId"
            control={control}
            defaultValue={defaultValue}
            render={({field: {onChange, value}}) =>
                <FormControl size='sm' sx={{flexGrow: 1}}>
                    <FormLabel>Assuntos</FormLabel>
                    <Autocomplete multiple
                                  groupBy={(option) => option.discipline.name}
                                  getOptionKey={option => option.id}
                                  disabled={disciplineId.length === 0}
                                  getOptionLabel={option => option.name}
                                  options={subjects}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  onChange={(event, newValue) => onChange(newValue)}
                                  value={value}
                                  limitTags={2}
                                  noOptionsText="Sem opções"
                                  loading={loading}
                                  loadingText="Carregando..."
                                  endDecorator={
                                      loading ? (
                                          <CircularProgress size="sm" sx={{bgcolor: 'background.surface'}}/>
                                      ) : null
                                  }
                    />
                </FormControl>}/>


    )
}
