import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import {useState} from "react";
import * as React from "react";
import ModuleMenu from "./ModuleMenu";
import ModuleRemoveModal from "./ModuleRemoveModal";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export default function ModuleRow({courseId, module, provider}) {
    const [remove, setRemove] = useState(false);

    return (
        <>
            <ModuleRemoveModal courseId={courseId} module={module} open={remove} onClose={() => setRemove(false)}/>
            <tr key={module.id}
                {...provider.draggableProps}
                ref={provider.innerRef}>
                <td
                    scope="row"
                    {...provider.dragHandleProps}
                >
                    <DragIndicatorIcon/>
                </td>
                <td style={{paddingLeft: '16px'}}
                    {...provider.dragHandleProps}
                >
                    <Typography level="body-xs">{module.position}</Typography>
                </td>
                <td style={{padding: '12px 6px'}}
                    {...provider.dragHandleProps}
                >
                    <Typography level="body-xs">{module.name}</Typography>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <ModuleMenu module={module} onDelete={() => setRemove(true)}/>
                    </Box>
                </td>
            </tr>
        </>
    )
}
