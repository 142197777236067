import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import * as React from "react";
import {Controller, useForm} from "react-hook-form";
import {useState} from "react";
import Stack from "@mui/joy/Stack";
import Input from "@mui/joy/Input";
import axios from "axios";
import {mutate} from "swr";
import ErrorAlert from "../../../../../components/ErrorAlert";
import Button from "@mui/joy/Button";
import {FormHelperText, styled} from "@mui/joy";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function LinkForm({lecture, link, onClose}) {
    const [submiting, setSubmiting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [file, setFile] = useState(null);

    const {handleSubmit, control, formState: {errors}} = useForm({mode: 'all'});

    if (!lecture) {
        throw new Error("lecture cannot be null");
    }
    const handleSubmitWithoutPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
    };


    const onSubmit = (data) => {
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        setSubmiting(true);
        setSubmitError(undefined);
        (link ? axios.put(`lecture/${lecture.id}/link/${link.id}`, data) : axios.post(`lecture/${lecture.id}/link`, data))
            .then(response => {
                return mutate((key) => typeof key === 'string' && key.startsWith(`/lecture/${lecture.id}`))
                    .then(() => onClose(response.data));
            })
            .catch(error => {
                setSubmitError(error);
            })
            .finally(() => setSubmiting(false));
    }

    const handleChange = (event) => {
        setFile(event.target.files[0]);
    }

    return (
        <>
            <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
            <form onSubmit={handleSubmitWithoutPropagation}>
                <Stack gap={2}>
                    <Controller
                        name="position"
                        defaultValue={link?.position || ''}
                        control={control}
                        rules={{required: 'Campo obrigatório'}}
                        helperText={errors.position?.message}
                        render={({field}) => <FormControl size="sm" sx={{flexGrow: 1}}
                                                          error={!!errors.position}>
                            <FormLabel>Posição</FormLabel>
                            <Input type="number" {...field}/>
                            <FormHelperText>{errors.position?.message}</FormHelperText>
                        </FormControl>}
                    />
                    <Controller
                        name="name"
                        defaultValue={link?.name || ''}
                        control={control}
                        rules={{required: 'Digite o nome do linko'}}
                        helperText={errors.name?.message}
                        render={({field}) =>
                            <FormControl size="sm" sx={{flexGrow: 1}} error={!!errors.name}>
                                <FormLabel>Nome</FormLabel>
                                <Input{...field}/>
                                <FormHelperText>{errors.name?.message}</FormHelperText>
                            </FormControl>
                        }
                    />
                    <Controller
                        name="url"
                        defaultValue={link?.url || ''}
                        control={control}
                        rules={{required: 'Digite a url do link'}}
                        helperText={errors.url?.message}
                        render={({field}) =>
                            <FormControl size="sm" sx={{flexGrow: 1}} error={!!errors.url}>
                                <FormLabel>URL</FormLabel>
                                <Input{...field}/>
                                <FormHelperText>{errors.url?.message}</FormHelperText>
                            </FormControl>
                        }
                    />

                    <Button loading={submiting} type="submit">Salvar</Button>
                </Stack>
            </form>
        </>
    )
}
