import {Autocomplete, CircularProgress} from "@mui/joy";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import {Controller} from "react-hook-form";
import * as React from "react";
import {useSubject, useSubjectByDiscipline} from "../../../hooks/useSubject";
import ErrorAlert from "../../../components/ErrorAlert";
import SubjectAutocompleteContent from "./SubjectAutocompleteContent";

const getDefaultValue = (value, multiple) => {
    if (!value) {
        return multiple ? [] : undefined;
    } else {
        return value;
    }
}

export default function SubjectAutocomplete({
                                                disciplineId = [],
                                                control,
                                                defaultValue = [],
                                                sort = 'discipline.name,name'
                                            }) {
    const [data, loading, error] = useSubjectByDiscipline({disciplineId, sort});
    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }
    if (loading) {
        return (<Autocomplete options={[]} loading={true}/>)
    }


    const subjects = data?.content || [];
    if (!subjects) {
        return (<Autocomplete options={[]}/>)

    }
    const selected = getDefaultValue(subjects.filter(subject => defaultValue.includes(subject.id.toString())), true);

    return (<SubjectAutocompleteContent defaultValue={selected}
                                        subjects={subjects}
                                        control={control}
                                        disciplineId={disciplineId}
                                        loading={loading}/>
    )
}
