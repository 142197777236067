import {useState} from "react";
import axios from "axios";
import {DialogActions, DialogContent, Modal, ModalDialog} from "@mui/joy";
import DialogTitle from "@mui/joy/DialogTitle";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Divider from "@mui/joy/Divider";
import Button from "@mui/joy/Button";
import * as React from "react";
import {mutate} from "swr";
import ErrorAlert from "../../../../../components/ErrorAlert";

export default function LinkRemoveModal({lecture, link, open, onClose}) {
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    const handleClick = () => {
        setSubmitting(true);
        setSubmitError(null);
        axios.delete(`lecture/${lecture.id}/link/${link.id}`)
            .then(response => {
                return mutate((key) => typeof key === 'string' && key.startsWith(`/lecture/${lecture.id}`))
                    .then(() => onClose());
            })
            .catch(error => {
                setSubmitError(error);
            })
            .finally(() => setSubmitting(false));
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <WarningRoundedIcon/>
                    Remover Link
                </DialogTitle>
                <Divider/>
                <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
                <DialogContent>
                    Tem certeza que deseja remover este link?
                </DialogContent>
                <DialogActions>
                    <Button loading={submitting} variant="solid" color="danger" onClick={handleClick}>
                        Remover
                    </Button>
                    <Button variant="plain" color="neutral" onClick={onClose}>
                        Cancelar
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}
