import {CircularProgress, Step, StepButton, StepIndicator, Stepper} from "@mui/joy";
import {Check} from "@mui/icons-material";
import Box from "@mui/joy/Box";
import {useNavigate, useSearchParams} from "react-router-dom";
import * as React from "react";
import ErrorAlert from "../../../../components/ErrorAlert";
import {useLecture} from "../../../../hooks/useLecture";
import LectureRegistrationStepperContent from "./LectureRegistrationStepperContent";


export default function LectureRegistrationStepper({id}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [lecture, loading, error] = useLecture(id);
    const navigate = useNavigate();

    const steps = ['Dados Básicos', 'Vídeo', 'Documentos e Links'];
    const step = searchParams.get('step') || '0';
    const courseId = searchParams.get('courseId') || '';
    const moduleId = searchParams.get('moduleId') || '';
    const sectionId = searchParams.get('sectionId') || '';

    if (error) {
        return (
            <Box sx={{width: '100%', height: '100%', pt: 1}}>
                <ErrorAlert error={error}/>
            </Box>
        )
    }

    if (loading) {
        return (
            <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </Box>
        );
    }
    const activeStep = parseInt(step);

    const handleNext = () => {
        setSearchParams({
            step: (activeStep + 1).toString(),
            courseId,
            moduleId,
            sectionId
        });
    };

    const handleBack = (quit) => {
        if (quit || activeStep === 0) {
            if (courseId && moduleId && sectionId) {
                navigate(`/registration/course/edit/${courseId}/module/${moduleId}?sectionId=${sectionId}`)
            } else {
                navigate("/registration/lecture")
            }
        } else {
            setSearchParams({
                step: (activeStep - 1).toString(),
                courseId,
                moduleId,
                sectionId
            });
        }
    };

    return (
        <Box sx={{width: '100%', height: '100%', pt: 1}}>
            <Stepper sx={{width: '100%'}}>
                {steps.map((step, index) => (
                    <Step
                        key={step}
                        indicator={
                            <StepIndicator
                                variant={activeStep <= index ? 'soft' : 'solid'}
                                color={activeStep < index ? 'neutral' : 'primary'}
                            >
                                {activeStep <= index ? index + 1 : <Check/>}
                            </StepIndicator>
                        }
                        sx={{
                            '&::after': {
                                ...(activeStep > index &&
                                    index !== 2 && {bgcolor: 'primary.solidBg'}),
                            },
                        }}
                    >
                        <StepButton>{step}</StepButton>
                    </Step>
                ))}
            </Stepper>
            <LectureRegistrationStepperContent lecture={lecture}
                                               courseId={courseId}
                                               moduleId={moduleId}
                                               sectionId={sectionId}
                                               activeStep={activeStep}
                                               onBack={handleBack}
                                               onNext={handleNext}/>
        </Box>
    )
}
