import Breadcrumb from "../../../components/Breadcrumb";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import CourseList from "../courses/CourseList";
import PremiumBanner from "../my-courses/PremiumBanner";
import * as React from "react";
import {useParams} from "react-router-dom";
import ErrorAlert from "../../../components/ErrorAlert";
import {CircularProgress, useTheme} from "@mui/joy";
import {useInstitution} from "../../../hooks/useInstitution";
import InstitutionBanner from "../../../components/domain/institution/InstitutionBanner";
import CourseFilters from "../courses/CourseFilters";

export default function InstitutionPage() {
    let {institutionId} = useParams();
    institutionId = isNaN(Number(institutionId)) ? undefined : institutionId;
    const [institution, loading, error] = useInstitution(institutionId);

    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }

    if (loading) {
        return (
            <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </Box>
        );
    }

    if (!institution) {
        return undefined;
    }

    return (
        <>
            <Breadcrumb current={{name: institution.name}}/>
            <InstitutionBanner institution={institution} />
            <CourseFilters showInstitutionFilter={false}/>
            <CourseList institutionIdDefault={institution.id}/>
            <Box sx={{mt: 'auto'}}>
                <PremiumBanner authority={'CURSOS'}/>
            </Box>
        </>
    )
}