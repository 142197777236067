import useSWR from "swr";
import {fetcher} from "../core/axios-config";

export function useCourses({
                               page = 0,
                               size = 1000,
                               sort = 'date,desc',
                               name = '',
                               type = '',
                               id = [],
                               institutionId = [],
                               jobTitleId = [],
                               published = '',
                               myCourses = false,
                           }) {
    const {data, error, isLoading} = useSWR(`/course?page=${page}&sort=${sort}&size=${size}`
        + `&id=${id.join("&id=")}`
        + `&institutionId=${institutionId.join("&institutionId=")}`
        + `&jobTitleId=${jobTitleId.join("&jobTitleId=")}`
        + `&type=${type}`
        + `&name=${name}`
        + `&published=${published}`
        + `&myCourses=${myCourses}`
        , fetcher, {
            revalidateIfStale: true,
            revalidateOnFocus: false,
            revalidateOnReconnect: true
        });
    return [data, isLoading, error];
}
