import {Autocomplete, CircularProgress, FormHelperText} from "@mui/joy";
import {useDomain} from "../../hooks/useDomain";
import Stack from "@mui/joy/Stack";
import ErrorAlert from "../ErrorAlert";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import DomainModal from "./DomainModal";
import {useState} from "react";
import {Controller} from "react-hook-form";
import * as React from "react";
import AddDomainButton from "./AddDomainButton";
import JobTitleModal from "./job-title/JobTitleModal";
import TeacherModal from "./teacher/TeacherModal";
import SubjectModal from "./subject/SubjectModal";
import InstitutionModal from "./institution/InstitutionModal";

const getDefaultValue = (value, multiple) => {
    if (!value) {
        return multiple ? [] : null;
    } else {
        return value;
    }
}

export default function DomainSelect({
                                         control,
                                         name,
                                         defaultValue,
                                         domain,
                                         title,
                                         errors,
                                         sort,
                                         setValue,
                                         mandatory = true,
                                         multiple = false,
                                         canAdd = true,
                                         fullwidth = false
                                     }) {
    const [data, loading, error] = useDomain({domain, sort});
    const [open, setOpen] = useState(false);
    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }
    const items = data?.content || [];

    const handleClose = (value) => {
        if (value) {
            setValue(domain, value);
        }
        setOpen(false);
    }

   const modal = (() => {
        switch (domain) {
            case 'job-title':
                return <JobTitleModal open={open} onClose={handleClose}/>
            case 'teacher':
                return <TeacherModal open={open} onClose={handleClose}/>;
            case 'subject':
                return <SubjectModal open={open} onClose={handleClose}/>;
            case 'institution':
                return <InstitutionModal open={open} onClose={handleClose}/>;
            default:
                return <DomainModal open={open} domain={domain} title={title} onClose={handleClose}/>;
        }
    })();

    return (
        <>
            {modal}
            <Stack direction="row">
                <Controller
                    name={name || domain}
                    control={control}
                    rules={mandatory ? {required: 'Campo obrigatório'} : {}}
                    defaultValue={getDefaultValue(defaultValue, multiple)}
                    render={({field: {onChange, value}}) =>
                        <FormControl size='sm' sx={{flexGrow: 1}} error={!!errors[name || domain]}>
                            <FormLabel>{title}</FormLabel>
                            <Autocomplete multiple={multiple}
                                          getOptionKey={option => option.id}
                                          getOptionLabel={option => option.name}
                                          options={items}
                                          isOptionEqualToValue={(option, value) => option.id === value.id}
                                          onChange={(event, newValue) => onChange(newValue)}
                                          value={value}
                                          limitTags={2}
                                          noOptionsText="Sem opções"
                                          loading={loading}
                                          loadingText="Carregando..."
                                          endDecorator={
                                              loading ? (
                                                  <CircularProgress size="sm" sx={{bgcolor: 'background.surface'}}/>
                                              ) : null
                                          }/>
                            <FormHelperText>{errors[name || domain]?.message}</FormHelperText>
                        </FormControl>}/>
                <AddDomainButton show={canAdd} fullwidth={fullwidth} onClick={() => setOpen(true)}/>
            </Stack>
        </>
    )
}
