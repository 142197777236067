import {useState} from "react";
import axios from "axios";
import {useSWRConfig} from "swr";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import LectureSelect from "../../../../../components/lecture/LectureSelect";
import ErrorAlert from "../../../../../components/ErrorAlert";
import {Draggable, Droppable} from "@hello-pangea/dnd";
import {Alert, LinearProgress, Table} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import * as React from "react";
import SectionLectureMenu from "./SectionLectureMenu";
import Button from "@mui/joy/Button";
import {useNavigate} from "react-router-dom";

export default function LectureSectionTable({courseId, moduleId, sectionId, lecturesSection = [], onUpdate}) {
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const navigate = useNavigate();
    const {mutate} = useSWRConfig();

    const handleCreateLecture = () => {
        navigate(`/registration/lecture/new?courseId=${courseId}&moduleId=${moduleId}&sectionId=${sectionId}`);
    }

    const handleAddLecture = async (lecture) => {
        if (!lecture) {
            return;
        }
        setSubmitting(true);
        setSubmitError(undefined);
        onUpdate();
        try {
            await mutate(`/module/${moduleId}/section`, axios.patch(`section/${sectionId}/lecture/${lecture.id}`), {
                revalidate: false,
                populateCache: (response, sections) => {
                    for (const section of sections) {
                        if (section.id === sectionId) {
                            section.lectureSection = response.data;
                        }
                    }
                    return [...sections];
                },
                optimisticData: sections => {
                    for (const section of sections) {
                        if (section.id === sectionId) {
                            section.lectureSection = [...section.lectureSection, {
                                lecture: lecture,
                                position: section.lectureSection.length + 1
                            }];
                        }
                    }
                    return [...sections];
                },

            });
            await mutate(`/course/${courseId}/lecture`, lectures => {
                return lectures.filter(l => l.id !== lecture.id);
            }, {revalidate: false});
        } catch (error) {
            setSubmitError(error);
        }
        setSubmitting(false);
    }

    const handleRemoveLecture = async (lecture) => {
        setSubmitting(true);
        setSubmitError(undefined);
        onUpdate();
        try {
            await mutate(`/module/${moduleId}/section`, axios.delete(`section/${sectionId}/lecture/${lecture.id}`), {
                revalidate: false,
                populateCache: (response, sections) => {
                    for (const section of sections) {
                        if (section.id === sectionId) {
                            section.lectureSection = response.data;
                        }
                    }
                    return [...sections];
                },
                optimisticData: sections => {
                    for (const section of sections) {
                        if (section.id === sectionId) {
                            section.lectureSection = section.lectureSection.filter(ls => ls.lecture.id !== lecture.id);
                        }
                    }
                    return [...sections];
                },
                rollbackOnError: true
            });
            await mutate(`/course/${courseId}/lecture`, lectures => {
                return [...lectures, lecture].sort((a, b) => {
                    const nameA = a.discipline?.name?.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.discipline?.name?.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
            }, {
                revalidate: false
            });

        } catch (error) {
            setSubmitError(error);
        }
        setSubmitting(false);
        onUpdate();
    }

    return (
        <Sheet
            variant="soft"

            sx={{p: 1, pl: 6, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)'}}
        >
            <Stack spacing={1}>
                <Stack justifyContent="space-between" direction="row">
                    <Typography level="body-lg" component="div">
                        Selecionar Aulas
                    </Typography>
                    <Button variant="solid" onClick={handleCreateLecture}>Cadastrar nova aula</Button>
                </Stack>
                <LectureSelect onChange={handleAddLecture} courseId={courseId}/>
                <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
                {submitting ? <LinearProgress/> : undefined}
                <Droppable droppableId={"section-" + sectionId}>
                    {(dropProvided) => (
                        <Table
                            ref={dropProvided.innerRef} {...dropProvided.droppableProps}
                            borderAxis="bothBetween"
                            size="sm"
                            aria-label="purchases"
                            sx={{
                                '& > thead > tr > th:nth-child(n + 4), & > tbody > tr > td:nth-child(n + 4)':
                                    {textAlign: 'right'},
                                '--TableCell-paddingX': '0.5rem',
                            }}
                        >
                            {lecturesSection.length > 0 ?
                                <thead>
                                <tr>
                                    <th style={{width: 60}}>Posição</th>
                                    <th>Nome</th>
                                    <th style={{width: '25%'}}>Professor</th>
                                    <th style={{width: 60}}></th>
                                </tr>
                                </thead>
                                : <Alert sx={{width: 1, mx: 'auto'}}>Nenhuma aula adicionada.</Alert>
                            }
                            <tbody>
                            {lecturesSection.map((lectureSection, index) => (
                                <Draggable key={lectureSection.lecture.id}
                                           draggableId={"lecture-" + lectureSection.lecture.id.toString()}
                                           index={index}>
                                    {(provided, snapshot) => (
                                        <tr key={lectureSection.lecture.id}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}>
                                            <td style={{width: 60}}
                                                scope="row">{lectureSection.position}</td>
                                            <td style={{width: '70%'}}>{lectureSection.lecture.name}</td>
                                            <td style={{width: '25%'}}>{lectureSection.lecture.teacher.name}</td>
                                            <td style={{width: 60}}>
                                                <SectionLectureMenu
                                                    courseId={courseId}
                                                    moduleId={moduleId}
                                                    sectionId={sectionId}
                                                    lecture={lectureSection.lecture}
                                                    onDelete={handleRemoveLecture}/>
                                            </td>
                                        </tr>
                                    )}
                                </Draggable>
                            ))}
                            {dropProvided.placeholder}
                            </tbody>
                        </Table>
                    )}
                </Droppable>
            </Stack>
        </Sheet>
    )
}