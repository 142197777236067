import {AspectRatio} from "@mui/joy";
import ImageIcon from "@mui/icons-material/Image";
import * as React from "react";

export default function InstitutionImagePreview({institution, type}) {
    if (institution[type]) {
        return (
            <AspectRatio minHeight={60} maxHeight="200px">
                <img
                    src={`${process.env.REACT_APP_SERVER_URL}institution/${institution.id}/${type}`}
                    alt="Background"
                />
            </AspectRatio>
        )
    } else {
        return <ImageIcon sx={{fontSize: '3rem', opacity: 0.2}}/>
    }
}