import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import {Link as RouterLink, useParams} from "react-router-dom";
import * as React from "react";
import LectureRegistrationStepper from "./LectureRegistrationStepper";

export default function LectureRegistrationDetail() {
    let {id} = useParams();
    id = isNaN(Number(id)) ? undefined: id;
    const title = (id ? 'Alterar' : 'Adicionar') + ' Aula';

    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Breadcrumbs
                    size="sm"
                    aria-label="breadcrumbs"
                    separator={<ChevronRightRoundedIcon fontSize="sm"/>}
                    sx={{pl: 0}}
                >
                    <Link
                        underline="none"
                        color="neutral"
                        component={RouterLink} to="/"
                        aria-label="Home"
                    >
                        <HomeRoundedIcon/>
                    </Link>
                    <Link
                        underline="hover"
                        color="neutral"
                        component={RouterLink} to="/registration/lecture"
                        fontSize={12}
                        fontWeight={500}
                    >
                        Cadastro de Aulas
                    </Link>
                    <Typography color="primary" fontWeight={500} fontSize={12}>
                        {title}
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: {xs: 'column', sm: 'row'},
                    alignItems: {xs: 'start', sm: 'center'},
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    {title}
                </Typography>
            </Box>
            <LectureRegistrationStepper id={id}/>
        </>
    );
}
