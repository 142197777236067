import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import Card from "@mui/joy/Card";
import * as React from "react";
import { LinearProgress, styled} from "@mui/joy";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import { useState} from "react";
import CourseBackgroundPreview from "./CourseBackgroundPreview";
import {useSWRConfig} from "swr";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function CourseBackgroundCard({course}) {
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const {mutate} = useSWRConfig();

    const handleChange = (event) => {
        setSubmitting(true)
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        mutate(`/course/${course.id}?complete=true`, async course => {
            try {
                const response = await axios.put(`course/${course.id}/background`,
                    formData, {
                        headers: {"Content-Type": "multipart/form-data"}
                    });
                course.background = response.data.background;
                return course;
            } catch (error) {
                setSubmitError(error);
            }
        }, {revalidate: false}).then(() => {
            setSubmitting(false);
        });
    }

    return (
        <Card sx={{mt: 4, maxWidth: 'lg', width: 1, mx: 'auto'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1}}>
                <Typography level="title-md">Imagem de Fundo</Typography>
            </Box>
            <Divider/>
            <Stack justifyContent="center" spacing={2}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    {submitting ? <LinearProgress/> : <CourseBackgroundPreview course={course}/>}
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Button
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                        variant="outlined"
                        color="primary"
                        startDecorator={<CloudUploadIcon/>}
                    >
                        Selecionar Imagem
                        <VisuallyHiddenInput type="file" accept="image/*" onChange={handleChange}/>
                    </Button>
                </Box>
            </Stack>
        </Card>
    )
}