import IconButton from "@mui/joy/IconButton";
import {Add} from "@mui/icons-material";
import Box from "@mui/joy/Box";
import * as React from "react";

export default function AddDomainButton({show, fullwidth, onClick}) {
    if (show) {
        return (
            <IconButton size='sm' sx={{height: 34, width: 34, mt: '24px', ml: '8px'}} onClick={onClick}>
                <Add/>
            </IconButton>
        )
    } else if (fullwidth) {
        return null;
    } else {
        return <Box sx={{height: 34, width: 34, mt: '24px', ml: '8px'}}/>
    }
}
