import {useState} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/joy/IconButton";
import * as React from "react";
import SectionMenu from "./SectionMenu";
import SectionModal from "./SectionModal";
import LectureSectionTable from "./LectureSectionTable";
import SectionRemoveModal from "./SectionRemoveModal";
import {Draggable} from "@hello-pangea/dnd";
import Box from "@mui/joy/Box";
import {Grid} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import Divider from "@mui/joy/Divider";

export default function SectionRow({index, courseId, moduleId, section, initialOpen, onUpdate}) {
    const [open, setOpen] = useState(initialOpen || false);
    const [remove, setRemove] = useState(false);
    const [edit, setEdit] = useState(false);

    return (<Draggable key={section.id} draggableId={"section-" + section.id} index={index}>
            {(provided, snapshot) => (<>
                    <SectionModal open={edit} onClose={() => setEdit(false)} moduleId={moduleId} section={section}/>
                    <SectionRemoveModal open={remove} onClose={() => setRemove(false)} moduleId={moduleId}
                                        section={section}/>
                    <Stack
                        sx={{
                            justifyContent: 'space-between',
                            flexGrow: 1,
                            mb: 1,
                            "backgroundColor:hover": 'var(--joy-palette-background-level1)'
                        }}
                        key={section.id}
                        {...provided.draggableProps}
                        ref={provided.innerRef}>

                        <Stack direction="row"
                               justifyContent="space-between"
                               alignItems="center"
                               {...provided.dragHandleProps}
                        >
                            <Box sx={{width: 80}}>
                                <IconButton
                                    aria-label="expand row"
                                    variant="plain"
                                    color="neutral"
                                    size="sm"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                                </IconButton>
                            </Box>
                            <Box sx={{width: 120}}><Typography level="title-sm">{section.position} </Typography></Box>
                            <Box sx={{flexGrow: 1}}> <Typography level="title-sm">{section.name}</Typography></Box>
                            <Box sx={{width: 80}}><SectionMenu onEdit={() => setEdit(true)}
                                                               onDelete={() => setRemove(true)}/></Box>
                        </Stack>
                        {open && (<LectureSectionTable courseId={courseId} moduleId={moduleId} sectionId={section.id}
                                                       lecturesSection={section.lectureSection} onUpdate={onUpdate}/>)}
                        <Divider/>
                    </Stack>
                </>)}
        </Draggable>)
}
