import {Modal, ModalDialog} from "@mui/joy";
import DialogTitle from "@mui/joy/DialogTitle";
import * as React from "react";
import InstitutionForm from "./InstitutionForm";

export default function InstitutionModal({open, institution, onClose}) {
    return (
        <Modal open={open} onClose={() => onClose()}>
            <ModalDialog sx={{minWidth: {md: 500}}}>
                <DialogTitle>{institution ? 'Alterar' : 'Adicionar'} Instituição</DialogTitle>
                <InstitutionForm institution={institution} onClose={onClose}></InstitutionForm>
            </ModalDialog>
        </Modal>
    );
}
