import Card from "@mui/joy/Card";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import {Link as RouterLink} from "react-router-dom";
import FavButton from "../../../components/FavButton";
import * as React from "react";
import {useContext} from "react";
import {UserContext} from "../../../contexts/UserContext";
import {containsAuthority} from "../../../utils";

export default function CourseItem({course}) {
    const user = useContext(UserContext);

    if (containsAuthority('CURSOS', user)) {
        return (
            <Card>
                <Stack direction='row' sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography component={RouterLink} to={`/course/${course.id}`}
                                sx={{cursor: 'pointer', textDecoration: 'none', width: 1}}
                                level="body-lg"> {course.name} </Typography>
                    <FavButton course={course}/>
                </Stack>
            </Card>
        );
    }
    if (course.urlPortal) {
        return (
            <Card>
                <Typography component={RouterLink} to={`${course.urlPortal}`} target="_blank"
                            sx={{cursor: 'pointer', textDecoration: 'none', width: 1}}
                            level="body-lg"> {course.name} </Typography>
            </Card>
        )
    }
    return (
        <Card>
            <Typography level="body-lg"> {course.name} </Typography>
        </Card>
    )
}