import {Modal, ModalDialog} from "@mui/joy";
import DialogTitle from "@mui/joy/DialogTitle";
import * as React from "react";
import DocumentForm from "./DocumentForm";

export default function DocumentEditModal({lecture, document, open, onClose}) {
    if (!lecture) {
        throw new Error("lecture cannot be null");
    }
    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog sx={{minWidth: {md: 500}}}>
                <DialogTitle>{document ? 'Alterar' : 'Adicionar'} Documento</DialogTitle>
                <DocumentForm lecture={lecture} document={document} onClose={onClose}/>
            </ModalDialog>
        </Modal>
    );
}
