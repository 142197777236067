import Box from "@mui/joy/Box";
import {Breadcrumbs, Dropdown, Menu, MenuButton, MenuItem} from "@mui/joy";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import CourseTable from "./CourseTable";
import AddIcon from "@mui/icons-material/Add";
import {useState} from "react";
import Button from "@mui/joy/Button";
import CourseCreateModal from "./CourseCreateModal";
import CourseFilters from "./CourseFilters";


export default function CourseRegistrationPage() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    return (
        <>
            <CourseCreateModal open={open} onClose={() => setOpen(false)}/>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Breadcrumbs
                    size="sm"
                    aria-label="breadcrumbs"
                    separator={<ChevronRightRoundedIcon fontSize="sm"/>}
                    sx={{pl: 0}}
                >
                    <Link
                        underline="none"
                        color="neutral"
                        aria-label="Home"
                        component={RouterLink} to="/"
                    >
                        <HomeRoundedIcon/>
                    </Link>
                    <Typography color="primary" fontWeight={500} fontSize={12}>
                        Cadastro de Curso
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: 'row',
                    alignItems: {xs: 'start', sm: 'center'},
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    Cadastro de Curso
                </Typography>
                <Dropdown>
                    <Button
                        color="primary"
                        variant='solid'
                        onClick={() => setOpen(true)}
                        startDecorator={<AddIcon/>}
                        size="sm">
                        Adicionar
                    </Button>
                </Dropdown>
            </Box>
            <CourseFilters/>
            <CourseTable/>
        </>
    )
}
