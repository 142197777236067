import {Modal, ModalDialog} from "@mui/joy";
import DialogTitle from "@mui/joy/DialogTitle";
import * as React from "react";
import DomainForm from "./DomainForm";

export default function DomainModal({open, domain, item, title, onClose}) {
    return (
        <Modal open={open} onClose={() => onClose()}>
            <ModalDialog sx={{minWidth: {md: 500}}}>
                <DialogTitle>{item ? 'Alterar' : 'Adicionar'} {title}</DialogTitle>
                <DomainForm domain={domain} item={item} onClose={onClose}></DomainForm>
            </ModalDialog>
        </Modal>
    );
}
