import {Alert, CircularProgress, Table} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import Box from "@mui/joy/Box";
import * as React from "react";
import Divider from "@mui/joy/Divider";
import {useSearchParams} from "react-router-dom";
import ErrorAlert from "../../../../components/ErrorAlert";
import Paginator from "../../../../components/Paginator";
import CourseRow from "./CourseRow";
import {useCourses} from "../../../../hooks/useCourses";

export default function CourseTable() {
    const [searchParams, setSearchParams] = useSearchParams();
    const name = searchParams.get('name') || '';
    const institutionId = searchParams.getAll("institutionId");
    const jobTitleId = searchParams.getAll("jobTitleId");
    const type = searchParams.get("type") || '';
    const published = searchParams.get("published") || '';
    const page = searchParams.get('page') || 1;
    const size = searchParams.get('size') || 10;
    const sort = searchParams.get('sort') || 'institutionId.name,jobTitle.name,name';

    const [data, loading, error] = useCourses(
        {
            institutionId,
            jobTitleId,
            name,
            type,
            published,
            page: page - 1,
            size,
            sort,
        }
    );

    if (loading) {
        return (
            <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </Box>
        );
    }

    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }

    if (!data) {
        return null;
    }

    const courses = data.content || [];
    const totalPages = data.totalPages || 0;

    if (courses.length === 0) {
        return (
            <Alert sx={{maxWidth: 800, width: 1, mx: 'auto'}}>Nenhum curso encontrado.</Alert>
        )
    }
    return (
        <Sheet
            className="OrderTableContainer"
            variant="outlined"
            sx={{
                display: 'initial',
                mx: 'auto',
                borderRadius: 'sm',
                flexShrink: 1,
                overflow: 'auto',
                minHeight: 0,
            }}
        >
            <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                }}
            >
                <thead>
                <tr>
                    <th style={{width: 40, padding: '12px 12px 12px 16px'}}>Id</th>
                    <th style={{width: 200, padding: '12px 6px'}}>Nome</th>
                    <th style={{width: 100, padding: '12px 6px'}}>Tipo</th>
                    <th style={{width: 180, padding: '12px 6px'}}>Instituição</th>
                    <th style={{width: 180, padding: '12px 6px'}}>Cargo</th>
                    <th style={{width: 100, padding: '12px 6px'}}>Publicado</th>
                    <th style={{width: 140, padding: '12px 6px'}}>Data de Criação</th>
                    <th style={{width: 140, padding: '12px 6px'}}>Criado por</th>
                    <th style={{width: 140, padding: '12px 6px'}}>Data de Atualização</th>
                    <th style={{width: 140, padding: '12px 6px'}}>Atualizado por</th>
                    <th style={{width: 40, padding: '12px 6px'}}></th>
                </tr>
                </thead>
                <tbody>
                {courses.map((course) => (
                    <CourseRow key={course.id} course={course}/>
                ))}
                </tbody>
            </Table>
            <Divider/>
            <Paginator sx={{p: '16px'}}
                       page={page}
                       totalPages={totalPages}
                       onPageChange={page => setSearchParams({page})}/>
        </Sheet>
    )
}
