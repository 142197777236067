import {iconButtonClasses} from "@mui/joy";
import Button from "@mui/joy/Button";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function pagination(c, m) {
    var current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

    for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || i >= left && i < right) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

function PageButton({selected, page, onPageChange}) {
    let variant = 'plain';
    if (Number(page)) {
        variant = 'outlined';
    }
    if (page === selected) {
        variant = 'solid';
    }

    return (
        <IconButton
            key={page}
            size="sm"
            variant={variant}
            onClick={() => {
                if (Number(page)) {
                    onPageChange(page);
                }
            }}
            color="primary"
        >
            {page}
        </IconButton>
    )
}

export default function Paginator({page, totalPages, onPageChange}) {
    page = Number(page);
    const pages = pagination(page, totalPages);
    if (pages.length < 2) {
        return null
    }
    return (
        <Box
            className="Pagination-laptopUp"
            sx={{
                p: 2, gap: 1, [`& .${iconButtonClasses.root}`]: {borderRadius: '50%'}, display:  'flex',
            }}
        >
            <Button
                size="sm"
                variant="outlined"
                color="primary"
                startDecorator={<KeyboardArrowLeftIcon/>}
                onClick={() => onPageChange(page - 1)}
                disabled={page <= 1}
            >
                Anterior
            </Button>

            <Box sx={{flex: 1}}/>
            {pages.map((p) => (
                <PageButton key={p} selected={page} page={p} onPageChange={onPageChange}/>
            ))}
            <Box sx={{flex: 1}}/>
            <Button
                size="sm"
                variant="outlined"
                color="primary"
                endDecorator={<KeyboardArrowRightIcon/>}
                onClick={() => onPageChange(page + 1)}
                disabled={page >= totalPages}
            >
                Próximo
            </Button>
        </Box>
    )
}
