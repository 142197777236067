import useSWR from "swr";
import {fetcher} from "../core/axios-config";

export function useCourseLectures({
                                courseId,
                            }) {
    const {data, error, isLoading} = useSWR(courseId ? `/course/${courseId}/lecture` : undefined, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
    });
    return [data, isLoading, error];
}
