import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import ErrorAlert from "../../../../../components/ErrorAlert";
import * as React from "react";
import Card from "@mui/joy/Card";
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import {CardContent, FormHelperText} from "@mui/joy";
import axios from "axios";
import {mutate} from "swr";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Button from "@mui/joy/Button";
import PandaVideo from "../../../../../components/video/PandaVideo";
import Stack from "@mui/joy/Stack";

export default function LectureVideoStep({lecture, onBack, onNext}) {
    const [submiting, setSubmiting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const {handleSubmit, control, setValue, getValues, formState: {errors}} = useForm({mode: 'all'});

    if (!lecture) {
        return null;
    }

    const onSubmit = (data) => {
        submit(data, false);
    }

    const submit = (data, quit) => {
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        setSubmiting(true);
        setSubmitError(undefined);
        axios.put(`lecture/${lecture?.id}/video/${data.videoId}`)
             .then(() => {
                 return mutate((key) => typeof key === 'string' && key.startsWith(`/lecture`))
                     .then(() => {
                         if (quit) {
                             onBack(true);
                         } else {
                             onNext();
                         }
                     });
             })
             .catch(error => {
                 setSubmitError(error);
             })
             .finally(() => setSubmiting(false));
    }

    const handleChange = (value) => {
        const result = value?.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/);
        if (result?.length > 0) {
            setValue('videoId', result[0]);
        } else {
            setValue('videoId', '');
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{mt: 4, maxWidth: 'lg', mx: 'auto'}}>
                <Box sx={{mb: 1}}>
                    <Typography level="title-md">Vídeo</Typography>
                </Box>
                <Divider/>
                <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
                <CardContent>
                        <Controller
                            name="videoId"
                            defaultValue={lecture?.video?.videoId || ""}
                            control={control}
                            helperText={errors.videoId?.message}
                            render={({field}) =>
                                <FormControl size="sm" sx={{flexGrow: 1}} error={!!errors.videoId}>
                                    <FormLabel>Id do vídeo</FormLabel>
                                    <Input type="text" {...field}
                                           onChange={(event) => handleChange(event.target.value)}/>
                                    <FormHelperText>Copiar url do dashboard do panda vídeo para extrair o id. Ex:
                                        https://dashboard.pandavideo.com.br/#/videos/41f779f1-c714-421c-b453-73e268652d6f</FormHelperText>
                                    <FormHelperText>{errors.videoId?.message}</FormHelperText>
                                </FormControl>
                            }
                        />
                    {lecture?.video?.videoHls ? <Box>
                        <FormLabel>Preview</FormLabel>
                        <PandaVideo
                            url={lecture?.video?.videoHls}
                            width='100%'
                            height='100%'/>
                    </Box> : undefined}
                </CardContent>
                <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                    <CardActions sx={{justifyContent: 'space-between', pt: 2}}>
                        <Button size="sm" variant="soft" onClick={() => onBack(false)}>
                            Voltar
                        </Button>
                        <Stack direction="row" spacing={1}>
                            <Button loading={submiting} type="button" size="sm" variant="outlined"
                                    onClick={() => submit(getValues(), true)}>
                                Salvar e Sair
                            </Button>
                            <Button loading={submiting} type="submit" size="sm" variant="solid">
                                Próximo
                            </Button>
                        </Stack>
                    </CardActions>
                </CardOverflow>
            </Card>
        </form>
    )
}
