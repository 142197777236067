import * as React from "react";
import {useRef, useState} from "react";
import axios from "axios";
import {LinearProgress, styled, useTheme} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import {useMediaQuery} from "@mui/material";
import PageTitle from "../../PageTitle";
import InstitutionImagePreview from "./InstitutionImagePreview";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function InstitutionBanner({institution, edit = false, fallback = true, mutate}) {
    const [submitting, setSubmitting] = useState(false);
    const fileInput = useRef(null)
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    if (!institution) {
        return undefined;
    }

    const handleChange = (event) => {
        setSubmitting(true)
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        mutate(async page => {
            try {
                const response = await axios.put(`institution/${institution.id}/banner`,
                    formData, {
                        headers: {"Content-Type": "multipart/form-data"}
                    });
                page.content = page.content.map(i => i.id === institution.id ? response.data : i);
                return page;
            } catch (error) {
                // setSubmitError(error);
            }
        }, {revalidate: true}).then(() => {
            setSubmitting(false);
        });
    }

    if (!fallback && !institution.banner) {
        return undefined;
    }

    const maxHeight = matches ? 250 : 120;
    const level = matches ? 'h1' : 'h3';

    if (edit) {
        return (<>
            <VisuallyHiddenInput ref={fileInput}
                                 type="file"
                                 accept="image/*"
                                 onChange={handleChange}/>
            <Box sx={{cursor: 'pointer', width: 1}} onClick={() => {
                fileInput.current?.click();
            }}>
                {submitting ? <LinearProgress/> : <InstitutionImagePreview institution={institution} type="banner"/>}
            </Box>
        </>)
    } else if (institution.banner) {
        return (
            <div className="hero-image"
                 style={{backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}institution/${institution.id}/banner")`, height: maxHeight}}>
                <div className="hero-text">
                    <Typography sx={{color: 'white'}} level={level} component="div">
                        {institution.name}
                    </Typography>
                </div>
            </div>
        )
    } else {
        return (
            <PageTitle>
                {institution.name}
            </PageTitle>
        );
    }
}