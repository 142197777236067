import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import {useState} from "react";
import * as React from "react";
import CourseMenu from "./CourseMenu";
import dayjs from "dayjs";
import CourseCreateModal from "./CourseCreateModal";
import Chip from "@mui/joy/Chip";
import axios from "axios";
import {mutate} from "swr";
import ErrorAlert from "../../../../components/ErrorAlert";
import CourseRemoveModal from "./CourseRemoveModal";

export default function CourseRow({course}) {
    const [remove, setRemove] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [submiting, setSubmiting] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    const handlePublish = () => {
        setSubmiting(true);
        setSubmitError(undefined);
        axios.patch(`course/${course.id}/publish`, {publish: !course.published})
             .then(response => {
                 course.published = !course.published;
             })
             .catch(error => {
                 setSubmitError(error);
             })
             .finally(() => setSubmiting(false));
    }

    return (
        <>
            <CourseRemoveModal course={course} open={remove} onClose={() => setRemove(false)}/>
            <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
            <CourseCreateModal course={course} open={duplicate} onClose={() => setDuplicate(false)}/>
            <tr>
                <td style={{paddingLeft: '16px'}}>
                    <Typography level="body-xs">{course.id}</Typography>
                </td>
                <td style={{paddingLeft: '12px 6px'}}>
                    <Typography level="body-xs">{course.name}</Typography>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Typography level="body-xs">{course.typeName}</Typography>
                </td>
                <td style={{padding: '12px 12px 12px 16px'}}>
                    <Typography level="body-xs">{course.institution?.name}</Typography>

                </td>
                <td style={{padding: '12px 6px'}}>
                    <Typography level="body-xs">{course.jobTitle?.name}</Typography>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Typography level="body-xs">{course.published ?
                        <Chip size="sm" color="success">Publicado</Chip> :
                        <Chip size="sm" color="warning">Rascunho</Chip>}
                    </Typography>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Typography
                        level="body-xs">{dayjs(course.createdAt).format('D MMM YYYY HH:mm:ss')}</Typography>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Typography level="body-xs">{course.userCreatedBy?.name}</Typography>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Typography
                        level="body-xs">{course.updatedAt ? dayjs(course.updatedAt).format('D MMM YYYY HH:mm:ss') : ''}</Typography>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Typography level="body-xs">{course.userUpdatedBy?.name}</Typography>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <CourseMenu course={course}
                                    onDuplicate={() => setDuplicate(true)}
                                    onPublish={handlePublish}
                                    onDelete={() => setRemove(true)}/>
                    </Box>
                </td>
            </tr>
        </>
    )
}
