import useSWR from "swr";
import {fetcher} from "../core/axios-config";

export function useCourse(id, complete = false) {
    const {data, error, isLoading, mutate} = useSWR(id ? `/course/${id}?complete=${complete}` : undefined, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
    });
    return [data, isLoading, error];
}

