import * as React from 'react';
import PropTypes from 'prop-types';
import {NumericFormat} from 'react-number-format';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';

export const NumericFormatAdapter = React.forwardRef(
    function NumericFormatAdapter(props, ref) {
        const {onChange, ...other} = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator="."
                decimalSeparator=","
                valueIsNumericString
                decimalScale={2}
                fixedDecimalScale
                prefix="R$ "
            />
        );
    },
);

NumericFormatAdapter.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function InputNumberFormat() {
    const [value, setValue] = React.useState('');
    return (
        <FormControl>
            <FormLabel>React number format</FormLabel>
            <Input
                value={value}
                onChange={(event) => setValue(event.target.value)}
                slotProps={{
                    input: {
                        component: NumericFormatAdapter,
                    },
                }}
            />
        </FormControl>
    );
}