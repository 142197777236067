import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import {useState} from "react";
import * as React from "react";
import Link from "@mui/joy/Link";
import DocumentEditModal from "./DocumentEditModal";
import DocumentRemoveModal from "./DocumentRemoveModal";
import axios from "axios";
import BasicMenu from "../../../../../components/BasicMenu";

export default function DocumentRow({lecture, document}) {
    const [edit, setEdit] = useState(false);
    const [remove, setRemove] = useState(false);

    const handleDownload = () => {
        axios({
            url: `document/${document.id}/download`, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = window.document.createElement('a');
            link.href = href;
            link.setAttribute('download', document.name.toLowerCase() + '.pdf'); //or any other extension
            window.document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            window.document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }
    return (
        <>
            <DocumentEditModal lecture={lecture}
                               document={document}
                               open={edit}
                               onClose={() => setEdit(false)}/>
            <DocumentRemoveModal lecture={lecture}
                                 document={document}
                                 open={remove}
                                 onClose={() => setRemove(false)}/>
            <tr>
                <td style={{padding: '12px 12px 12px 16px'}}>
                    <Typography level="body-xs">{document.position}</Typography>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Typography level="body-xs">{document.name}</Typography>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Link level="title-sm" onClick={handleDownload}>
                        Baixar
                    </Link>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <BasicMenu
                            onEdit={() => setEdit(true)}
                            onDelete={() => setRemove(true)}/>
                    </Box>
                </td>
            </tr>
        </>
    )
}
