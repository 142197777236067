import {Autocomplete, CircularProgress} from "@mui/joy";
import SchoolIcon from '@mui/icons-material/School';
import FormControl from "@mui/joy/FormControl";
import * as React from "react";
import ErrorAlert from "../ErrorAlert";
import {useLectures} from "../../hooks/useLectures";
import {useCourseLectures} from "../../hooks/useCourseLectures";

export default function LectureSelect({
                                          courseId,
                                          onChange,
                                      }) {
    const [data, loading, error] = useCourseLectures({
        courseId: courseId,
    });

    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }

    const items = data || [];

    return (
        <FormControl size='sm' sx={{flexGrow: 1}}>
            <Autocomplete getOptionLabel={option => option.name}
                          getOptionKey={option => option.id}
                          groupBy={(option) => option.discipline?.name || 'Sem Disciplina'}
                          options={items}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => onChange(newValue)}
                          limitTags={2}
                          startDecorator={<SchoolIcon/>}
                          placeholder="Selecione uma aula já cadastrada para adicionar a esse curso"
                          openOnFocus={true}
                          noOptionsText="Sem opções"
                          loading={loading}
                          loadingText="Carregando..."
                          endDecorator={
                              loading ? (
                                  <CircularProgress size="sm" sx={{bgcolor: 'background.surface'}}/>
                              ) : null
                          }
            />
        </FormControl>
    )
}
