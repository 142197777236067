import useSWR from "swr";
import {fetcher} from "../core/axios-config";

export function useDocuments({lectureId}) {
    const {data, error, isLoading} = useSWR(lectureId ? `/lecture/${lectureId}/document` : undefined,
        fetcher, {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: true
        });
    return [data, isLoading, error];
}