import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/joy/Divider";
import * as React from "react";
import Card from "@mui/joy/Card";
import {useSections} from "../../../../../hooks/useSections";
import ErrorAlert from "../../../../../components/ErrorAlert";
import {Alert, CircularProgress} from "@mui/joy";
import SectionModal from "./SectionModal";
import {useReducer, useState} from "react";
import SectionTableContent from "./SectionTableContent";
import Stack from "@mui/joy/Stack";
import SectionRenameModal from "./SectionRenameModal";
import axios from "axios";
import {mutate} from "swr";

export default function SectionTable({courseId, moduleId}) {
    if (!courseId) {
        throw new Error("courseId is required");
    }
    if (!moduleId) {
        throw new Error("courseId is required");
    }
    const [open, setOpen] = useState(false);
    const [rename, setRename] = useState(false);

    const [sections, loading, error] = useSections({
        courseId,
        moduleId,
    });
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const handleRename = () => {
        setSubmitting(true);
        setSubmitError(undefined);
        axios.patch(`module/${moduleId}/section/name`)
             .then(response => {
                 return mutate((key) => typeof key === 'string' && key.startsWith(`/module/${moduleId}/section`), undefined, {revalidate: true});
             })
             .catch(error => {
                 setSubmitError(error);
             })
             .finally(() => setSubmitting(false));
    }

    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }

    if (loading) {
        return (
            <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </Box>
        );
    }

    if (!sections) {
        return undefined;
    }

    return (
        <Card sx={{mt: 4, width: 1, maxWidth: 'lg', mx: 'auto'}}>
            <SectionModal open={open} onClose={() => setOpen(false)} moduleId={moduleId}/>
            <SectionRenameModal open={rename} onClose={() => setRename(false)} moduleId={moduleId}/>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1}}>
                <Typography level="title-md">Seções</Typography>
                <Stack direction="row" spacing={2}>
                    <Button size="sm"
                            type="button"
                            sx={{mt: 1}}
                            loading={submitting}
                            onClick={handleRename}
                    >
                        Renomear em lote
                    </Button>
                    <Button size="sm"
                            type="button"
                            sx={{mt: 1}}
                            startDecorator={<AddIcon/>}
                            onClick={() => setOpen(true)}
                    >
                        Adicionar
                    </Button>
                </Stack>
            </Box>
            <Divider/>
            <ErrorAlert error={submitError} onClose={() => setSubmitError(undefined)}/>
            {sections.length > 0 ?
                <SectionTableContent courseId={courseId}
                                     moduleId={moduleId}
                                     sections={sections}
                                     onError={(error) => setSubmitError(error)}
                                     onUpdate={() => forceUpdate()}
                />: <Alert sx={{width: 1, mx: 'auto'}}>Nenhuma seção adicionada.</Alert>
            }
        </Card>
    )
}
