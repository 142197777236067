import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import ModuleCreateModal from "./module/ModuleCreateModal";
import Divider from "@mui/joy/Divider";
import ModuleTable from "./module/ModuleTable";
import Card from "@mui/joy/Card";
import * as React from "react";

export default function CourseModulesCard({course}) {
    return (
        <Card sx={{mt: 4, maxWidth: 'lg', width: 1, mx: 'auto'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1}}>
                <Typography level="title-md">Módulos</Typography>
                <ModuleCreateModal courseId={course.id}/>
            </Box>
            <Divider/>
            <ModuleTable courseId={course.id} defaultModules={course.modules} key={course.modules}/>
        </Card>
    )
}