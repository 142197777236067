import {Modal, ModalDialog} from "@mui/joy";
import DialogTitle from "@mui/joy/DialogTitle";
import * as React from "react";
import TeacherForm from "./TeacherForm";

export default function TeacherModal({open, teacher, onClose}) {
    return (
        <Modal open={open} onClose={() => onClose()}>
            <ModalDialog sx={{minWidth: {md: 500}}}>
                <DialogTitle>{teacher ? 'Alterar' : 'Adicionar'} Professor</DialogTitle>
                <TeacherForm teacher={teacher} onClose={onClose}></TeacherForm>
            </ModalDialog>
        </Modal>
    );
}
