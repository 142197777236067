import Box from "@mui/joy/Box";
import {Breadcrumbs} from "@mui/joy";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import LectureTable from "./LectureTable";
import Button from "@mui/joy/Button";
import AddIcon from "@mui/icons-material/Add";
import LectureFilters from "./LectureFilters";


export default function LectureRegistrationPage() {
    const navigate = useNavigate();

    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Breadcrumbs
                    size="sm"
                    aria-label="breadcrumbs"
                    separator={<ChevronRightRoundedIcon fontSize="sm"/>}
                    sx={{pl: 0}}
                >
                    <Link
                        underline="none"
                        color="neutral"
                        aria-label="Home"
                        component={RouterLink} to="/"
                    >
                        <HomeRoundedIcon/>
                    </Link>
                    <Typography color="primary" fontWeight={500} fontSize={12}>
                        Cadastro de Aulas
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: 'row',
                    alignItems: {xs: 'start', sm: 'center'},
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    Cadastro de Aulas
                </Typography>
                <Button
                    color="primary"
                    startDecorator={<AddIcon/>}
                    size="sm"
                    onClick={() => navigate(`new`)}>
                    Adicionar
                </Button>
            </Box>
            <LectureFilters/>
            <LectureTable/>
        </>
    )
}
