import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import * as React from "react";

export default function PageTitle({children}) {
    return (
        <Box
            sx={{
                display: 'flex',
                mb: 1,
                gap: 1,
                flexDirection: 'row',
                alignItems: {xs: 'start', sm: 'center'},
                flexWrap: 'wrap',
                justifyContent: 'space-between',
            }}
        >
            <Typography level="h2" component="h1">
                {children}
            </Typography>
        </Box>
    )
}