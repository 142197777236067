import {Controller, useForm} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import {mutate} from "swr";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import {FormHelperText, Grid} from "@mui/joy";
import DomainAutocomplete from "../../../../components/domain/DomainAutocomplete";
import * as React from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import SubjectAutocomplete from "../../question-view/SubjectAutocomplete";

export default function LectureFilters() {
    const {handleSubmit, control, watch, reset} = useForm({mode: 'all'});
    const [searchParams, setSearchParams] = useSearchParams();
    const name = searchParams.get("name");
    const teacherId = searchParams.getAll("teacherId");
    const disciplineId = searchParams.getAll("disciplineId");
    const subjectId = searchParams.getAll("subjectId");
    const disciplineIdWatched = watch('disciplineId');

    const onSubmit = (data) => {
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        setSearchParams({
            name: data.name,
            teacherId: data.teacherId.map(x => x.id),
            disciplineId: data.disciplineId.map(x => x.id),
            subjectId: data.subjectId.map(x => x.id),

        });
        mutate((key) => typeof key === 'string' && key.startsWith(`/lecture`));
    }
    return (
        <Box
            className="SearchAndFilters-tabletUp"
            sx={{
                borderRadius: 'sm',
                py: 2,
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1.5, '& > *': {
                    minWidth: {xs: '120px', md: '160px'},
                },
            }}
        >
            <form style={{width: "100%"}} onSubmit={handleSubmit(onSubmit)}>
                <Grid sx={{width: 1}} container spacing={1}>
                    <Grid xl={6} xs={12}>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue={name || ''}
                            render={({field}) => <FormControl size="sm" sx={{flexGrow: 1}}>
                                <FormLabel>Nome</FormLabel>
                                <Input type="text" {...field}/>
                            </FormControl>}
                        />
                    </Grid>
                    <Grid xl={2} lg={3} md={4} sm={6} xs={12}>
                        <DomainAutocomplete control={control}
                                            domain="teacher"
                                            defaultValue={teacherId}
                                            name="teacherId"
                                            title="Professor"
                        />
                    </Grid>
                    <Grid xl={2} lg={3} md={4} sm={6} xs={12}>
                        <DomainAutocomplete control={control}
                                            domain="discipline"
                                            defaultValue={disciplineId}
                                            name="disciplineId"
                                            title="Disciplina"
                        />
                    </Grid>
                    <Grid xl={2} lg={3} md={4} sm={6} xs={12}>
                        <SubjectAutocomplete control={control}
                                             disciplineId={disciplineIdWatched?.map(x => x.id) || disciplineId}
                                             defaultValue={subjectId}
                        />
                    </Grid>
                </Grid>
                <Box sx={{
                    width: 1, px: 1,
                    display: "flex",
                    justifyContent: "end",
                    gap: 1
                }}>
                    <Button sx={{mt: 2, width: 100}} type="button" variant="soft" size="sm" color="primary"
                            onClick={() => {
                                setSearchParams({});
                                reset();
                            }}>
                        Limpar
                    </Button>
                    <Button sx={{mt: 2, width: 100}} type="submit" size="sm" color="primary">
                        Filtrar
                    </Button>
                </Box>
            </form>
        </Box>
    );
}
