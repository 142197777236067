import {Modal, ModalDialog} from "@mui/joy";
import DialogTitle from "@mui/joy/DialogTitle";
import * as React from "react";
import JobTitleForm from "./JobTitleForm";

export default function JobTitleModal({open, jobTitle, onClose}) {
    return (
        <Modal open={open} onClose={() => onClose()}>
            <ModalDialog sx={{minWidth: {md: 500}}}>
                <DialogTitle>{jobTitle ? 'Alterar' : 'Adicionar'} Cargo</DialogTitle>
                <JobTitleForm jobTitle={jobTitle} onClose={onClose}></JobTitleForm>
            </ModalDialog>
        </Modal>
    );
}
