import * as React from "react";
import SectionRow from "./SectionRow";
import {DragDropContext, Droppable} from "@hello-pangea/dnd";
import axios from "axios";
import {useSearchParams} from "react-router-dom";
import Sheet from "@mui/joy/Sheet";
import {useSWRConfig} from "swr";

export default function SectionTableContent({courseId, moduleId, sections = [], onError, onUpdate}) {
    if (!moduleId) {
        throw new Error("moduleId is required");
    }
    const [searchParams] = useSearchParams();
    const selectedSection = parseInt(searchParams.get('sectionId')) || undefined;
    const {mutate} = useSWRConfig();

    const handleDragEnd = async (e) => {
        if (e.type === 'SECTION') {
            if (!e.destination) return;
            let tempData = Array.from(sections);
            let [source_data] = tempData.splice(e.source.index, 1);
            tempData.splice(e.destination.index, 0, source_data);
            for (let i = 0; i < tempData.length; i++) {
                tempData[i].position = i + 1;
            }
            await mutate(`/module/${moduleId}/section`, async sections => {
                await axios.patch(`module/${moduleId}/section/position`, tempData.map(m => m.id))
                return tempData;
            }, {
                revalidate: false,
                optimisticData: tempData,
                rollbackOnError(error) {
                    onError(error);
                    return true;
                }
            });
        } else {
            if (!e.destination) return;
            const sectionIdFrom = parseInt(e.source.droppableId.replace(/\D/g, ''));
            const sectionIdTo = parseInt(e.destination.droppableId.replace(/\D/g, ''));
            const position = e.destination.index + 1;
            const lectureId = parseInt(e.draggableId.replace(/\D/g, ''));

                        onUpdate();

            await mutate(`/module/${moduleId}/section`, axios.patch(`section/lecture/position`, {
                sectionIdFrom,
                sectionIdTo,
                position,
                lectureId
            }), {
                revalidate: false,
                populateCache: (response, sections) => {
                    for (const section of sections) {
                        if (section.id === response.data.sectionIdTo) {
                            section.lectureSection = response.data.lecturesSectionTo.sort((a, b) => a.position - b.position);;
                        }
                        if (section.id === response.data.sectionIdFrom) {
                            section.lectureSection = response.data.lecturesSectionFrom.sort((a, b) => a.position - b.position);;
                        }
                    }
                    return [...sections];
                },
                optimisticData: sections => {
                    const lecture = sections.flatMap(s => s.lectureSection)
                                            .find(ls => ls.lecture.id === lectureId).lecture;
                    for (const section of sections) {
                        if (section.id === sectionIdFrom) {
                            section.lectureSection = [...section.lectureSection.filter(ls => ls.lecture.id !== lectureId)];
                        }
                        if (section.id === sectionIdTo) {
                            let i = position;
                            for (const lectureSection of section.lectureSection) {
                                if (lectureSection.position >= position) {
                                    lectureSection.position = ++i;
                                }
                            }
                            section.lectureSection.push({position, lecture: lecture})
                            section.lectureSection.sort((a, b) => a.position - b.position);
                        }
                    }
                    return [...sections];
                }
            });
            onUpdate();

        }
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>

            {/*<thead>*/}
            {/*<tr>*/}
            {/*    <th style={{width: 80}} aria-label="empty"/>*/}
            {/*    <th style={{width: 120}}>Posição</th>*/}
            {/*    <th style={{width: '100%'}}>Nome</th>*/}
            {/*    <th style={{width: 80}}></th>*/}
            {/*</tr>*/}
            {/*</thead>*/}
            <Droppable droppableId="sections" type="SECTION">
                {(provided) => (
                    <Sheet
                        variant="plain"
                        sx={{
                            // display: {xs: 'none', sm: 'initial'},
                            width: '100%',
                            borderRadius: 'sm',
                            flexShrink: 1,
                            overflow: 'auto',
                            minHeight: 0,
                        }}
                        ref={provided.innerRef} {...provided.droppableProps}>
                        {sections.map((section, index) => (
                            <SectionRow key={section.id}
                                        courseId={courseId}
                                        moduleId={moduleId}
                                        section={section}
                                        index={index}
                                        onUpdate={onUpdate}
                                // initialOpen={selectedSection ? index === sections.findIndex(s => s.id === selectedSection) : index === 0}
                                        initialOpen={true}
                            />
                        ))}
                        {provided.placeholder}
                    </Sheet>
                )}
            </Droppable>
        </DragDropContext>
    )
}
