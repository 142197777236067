import {Dropdown, Menu, MenuButton, MenuItem} from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import Divider from "@mui/joy/Divider";
import * as React from "react";
import {useNavigate} from "react-router-dom";

export default function SectionLectureMenu({courseId, moduleId, sectionId, lecture, onDelete}) {
    const navigate = useNavigate();

    return (
        <Dropdown>
            <MenuButton
                slots={{root: IconButton}}
                slotProps={{root: {variant: 'plain', color: 'neutral', size: 'sm'}}}
            >
                <MoreHorizRoundedIcon/>
            </MenuButton>
            <Menu size="sm" sx={{minWidth: 140}}>
                <MenuItem
                    onClick={() => navigate(`/registration/lecture/${lecture.id}?courseId=${courseId}&moduleId=${moduleId}&sectionId=${sectionId}`)}>Editar</MenuItem>
                <MenuItem onClick={() => navigate(`/registration/lecture/${lecture.id}?courseId=${courseId}&moduleId=${moduleId}&sectionId=${sectionId}&step=1`)}>Vídeo</MenuItem>
                <MenuItem onClick={() => navigate(`/registration/lecture/${lecture.id}?courseId=${courseId}&moduleId=${moduleId}&sectionId=${sectionId}&step=2`)}>Documentos</MenuItem>
                <Divider/>
                <MenuItem onClick={() => onDelete(lecture)} color="danger">Remover</MenuItem>
            </Menu>
        </Dropdown>
    )
}
