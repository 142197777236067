import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import {useState} from "react";
import * as React from "react";
import Link from "@mui/joy/Link";
import BasicMenu from "../../../../../components/BasicMenu";
import LinkRemoveModal from "./LinkRemoveModal";
import LinkEditModal from "./LinkEditModal";

export default function LinkRow({lecture, link}) {
    const [edit, setEdit] = useState(false);
    const [remove, setRemove] = useState(false);

    return (
        <>
            <LinkEditModal lecture={lecture}
                               link={link}
                               open={edit}
                               onClose={() => setEdit(false)}/>
            <LinkRemoveModal lecture={lecture}
                                 link={link}
                                 open={remove}
                                 onClose={() => setRemove(false)}/>
            <tr>
                <td style={{padding: '12px 12px 12px 16px'}}>
                    <Typography level="body-xs">{link.position}</Typography>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Typography level="body-xs">{link.name}</Typography>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Link target="_blank" href={link.url} level="body-xs">
                        {link.url}
                    </Link>
                </td>
                <td style={{padding: '12px 6px'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <BasicMenu
                            onEdit={() => setEdit(true)}
                            onDelete={() => setRemove(true)}/>
                    </Box>
                </td>
            </tr>
        </>
    )
}
