import {Alert, CircularProgress, Table} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import Box from "@mui/joy/Box";
import * as React from "react";
import {useParams} from "react-router-dom";
import ErrorAlert from "../../../../../components/ErrorAlert";
import LinkRow from "./LinkRow";
import {useLinks} from "../../../../../hooks/useLinks";

export default function LinkTable({lecture}) {
    let {id} = useParams();
    id = isNaN(Number(id)) ? undefined : id;
    const [data, isLoading, error] = useLinks(
        {
            lectureId: id
        }
    );

    if (isLoading) {
        return (
            <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </Box>
        );
    }

    if (error) {
        return (
            <ErrorAlert error={error}/>
        )
    }

    if (!data) {
        return null;
    }

    const links = data || [];

    if (links.length === 0) {
        return (
            <Alert sx={{maxWidth: 800, width: 1, mx: 'auto'}}>Nenhum link cadastrado.</Alert>
        )
    }
    return (

        <Sheet
            variant="outlined"
            sx={{
                display: 'initial',
                mx: 'auto',
                borderRadius: 'sm',
                flexShrink: 1,
                overflow: 'auto',
                minHeight: 0,
            }}
        >
            <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                }}
            >
                <thead>
                <tr>
                    <th style={{width: 140, padding: '12px 12px 12px 16px'}}>Posição</th>
                    <th style={{width: 140, padding: '12px 6px'}}>Nome</th>
                    <th style={{width: 240, padding: '12px 6px'}}>URL</th>
                    <th style={{width: 80, padding: '12px 6px'}}></th>
                </tr>
                </thead>
                <tbody>
                {links.map((link) => (
                    <LinkRow key={link.id} lecture={lecture} link={link}/>
                ))}
                </tbody>
            </Table>
        </Sheet>
    )
}
