import LectureBasicInfoStep from "./LectureBasicInfoStep";
import LectureVideoStep from "./video/LectureVideoStep";
import LectureDocumentStep from "./document/LectureDocumentStep";

export default function LectureRegistrationStepperContent({lecture, courseId, moduleId, sectionId, activeStep, onBack, onNext}) {
    switch (activeStep) {
        case 0:
            return <LectureBasicInfoStep lecture={lecture}
                                         courseId={courseId}
                                         moduleId={moduleId}
                                         sectionId={sectionId}
                                         onBack={onBack}
                                         onNext={onNext}/>;
        case 1:
            return <LectureVideoStep lecture={lecture} onBack={onBack} onNext={onNext}/>;
        case 2:
            return <LectureDocumentStep lecture={lecture}
                                        courseId={courseId}
                                        moduleId={moduleId}
                                        sectionId={sectionId}
                                        onBack={onBack}/>;
        default:
            return null;
    }
}
