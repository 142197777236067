import useSWR from "swr";
import {fetcher} from "../core/axios-config";

export function useSections({moduleId}) {
    const {
        data,
        error,
        isLoading
    } = useSWR(`/module/${moduleId}/section`, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
    });
    return [data, isLoading, error];
}

