import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Typography from "@mui/joy/Typography";
import {Link as RouterLink, useParams} from "react-router-dom";
import * as React from "react";
import ErrorAlert from "../../../../../components/ErrorAlert";
import {CircularProgress} from "@mui/joy";
import SectionTable from "../section/SectionTable";
import {useModule} from "../../../../../hooks/useModule";
import ModuleEditForm from "./ModuleEditForm";
import Stack from "@mui/joy/Stack";

export default function ModuleEditPage() {
    let {courseId, moduleId} = useParams();
    courseId = isNaN(Number(courseId)) ? undefined : courseId;
    moduleId = isNaN(Number(moduleId)) ? undefined : moduleId;
    if (!courseId) {
        throw new Error("courseId is required");
    }
    if (!moduleId) {
        throw new Error("courseId is required");
    }
    const [module, loading, error] = useModule({courseId, moduleId});

    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Breadcrumbs
                    size="sm"
                    aria-label="breadcrumbs"
                    separator={<ChevronRightRoundedIcon fontSize="sm"/>}
                    sx={{pl: 0}}
                >
                    <Link
                        underline="none"
                        color="neutral"
                        component={RouterLink} to="/"
                        aria-label="Home"
                    >
                        <HomeRoundedIcon/>
                    </Link>
                    <Link
                        underline="hover"
                        color="neutral"
                        component={RouterLink} to="/registration/course"
                        fontSize={12}
                        fontWeight={500}
                    >
                        Cadastro de Cursos
                    </Link>
                    <Link
                        underline="hover"
                        color="neutral"
                        component={RouterLink} to={`/registration/course/edit/${courseId}`}
                        fontSize={12}
                        fontWeight={500}
                    >
                        Editar Curso
                    </Link>
                    <Typography color="primary" fontWeight={500} fontSize={12}>
                        Editar Módulo
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: {xs: 'column', sm: 'row'},
                    alignItems: {xs: 'start', sm: 'center'},
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Stack sx={{mb: 2}}>
                    <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                        <Typography level="h2">                    {module?.name}
                        </Typography>
                    </Stack>
                    <Typography level="body-md" color="neutral">
                        {module?.course.name}
                    </Typography>
                </Stack>
            </Box>
            {error ? <ErrorAlert error={error}/> : undefined}
            {loading ? (
                <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress/></Box>
            ) : (
                <>
                    <ModuleEditForm courseId={courseId} module={module}/>
                    <SectionTable courseId={courseId} moduleId={moduleId}/>
                </>
            )}
        </>
    );
}
