import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import Stack from "@mui/joy/Stack";
import {FormHelperText} from "@mui/joy";
import Button from "@mui/joy/Button";
import axios from "axios";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import * as React from "react";
import {mutate} from "swr";
import ErrorAlert from "../../ErrorAlert";

export default function TeacherForm({teacher, onClose}) {
    const [submiting, setSubmiting] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const {handleSubmit, control, formState: {errors}} = useForm({mode: 'all'});

    const handleSubmitWithoutPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
    };

    const onSubmit = (data) => {
        if (!data || Object.keys(data).length === 0) {
            return;
        }
        setSubmiting(true);
        setSubmitError(undefined);
        (teacher ? axios.put(`teacher/${teacher.id}`, data) : axios.post('teacher', data))
            .then(response => {
                return mutate((key) => typeof key === 'string' && key.startsWith(`/teacher`))
                    .then(() => onClose(response.data));
            })
            .catch(error => {
                setSubmitError(error);
            })
            .finally(() => setSubmiting(false));
    }

    return (
        <>
            <ErrorAlert error={submitError} onClose={() => setSubmitError(null)}/>
            <form onSubmit={handleSubmitWithoutPropagation}>
                <Stack gap={2}>
                    <Controller
                        name="name"
                        defaultValue={teacher?.name || ""}
                        control={control}
                        rules={{required: 'Campo obrigatório'}}
                        helperText={errors.name?.message}
                        render={({field}) =>
                            <FormControl size="sm" error={!!errors.name}>
                                <FormLabel>Nome</FormLabel>
                                <Input {...field}/>
                                <FormHelperText>{errors.name?.message}</FormHelperText>
                            </FormControl>
                        }
                    />
                    <Controller
                        name="email"
                        defaultValue={teacher?.email || ""}
                        control={control}
                        helperText={errors.email?.message}
                        render={({field}) =>
                            <FormControl size="sm" error={!!errors.email}>
                                <FormLabel>Email</FormLabel>
                                <Input {...field}/>
                                <FormHelperText>{errors.email?.message}</FormHelperText>
                            </FormControl>
                        }
                    />
                    <Controller
                        name="phone"
                        defaultValue={teacher?.phone || ""}
                        control={control}
                        helperText={errors.email?.message}
                        render={({field}) =>
                            <FormControl size="sm" error={!!errors.phone}>
                                <FormLabel>Telefone</FormLabel>
                                <Input {...field}/>
                                <FormHelperText>{errors.phone?.message}</FormHelperText>
                            </FormControl>
                        }
                    />
                    <Button loading={submiting} type="submit">Salvar</Button>
                </Stack>
            </form>
        </>
    );
}
